





import { Component, Vue } from "vue-property-decorator";
import usuarioModule from "@/store/modules/usuario-module";
import selectsheet from "./selectsheet.vue";
@Component({
  components: {
    selectsheet,
  },
})
export default class Home extends Vue {

  public async created() {
    await usuarioModule.getmiusuario_back();
  }

  public mounted() {
    // Remove initial loading
    const appLoading = document.getElementById("loading-bg");
    if (appLoading) {
      appLoading.style.display = "none";
    }
  }
}
